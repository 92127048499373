import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import SEO from '../components/seo';
import RestaurantList from '../components/restaurants/restaurantsList';

const RestaurantsPage = ({ data }) => {
	const { t } = useTranslation();
	const restaurants = data.allInternalRestaurants?.nodes || [];

	const restaurantsWithMiniature = restaurants.map((restaurant) => {
		const miniatureImage = restaurant?.images?.find((element) => element.type === 'MINIATURE')?.url;
		return {
			...restaurant,
			miniatureImage,
		};
	});

	return (
		<>
			<SEO title={t('restaurants.title')} />
			<RestaurantList restaurants={restaurantsWithMiniature.filter((r, k) => k !== restaurants.length - 1)} />
		</>
	);
};

export default RestaurantsPage;

export const query = graphql`
	{
		allInternalRestaurants {
			nodes {
				name
				slug
				images {
					url
					type
					createdAt
				}
				hours {
					type
					openTime
					closeTime
					day
				}
				localization {
					name
					lat
					lng
				}
			}
		}
	}
`;
