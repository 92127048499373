import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import RestaurantListItem from './restaurantListItem';

const StyledRestaurantListWrapper = styled.div`
  padding: 0;
`;
const RestaurantList = ({ restaurants }) => (
	<StyledRestaurantListWrapper>
		{restaurants.map((restaurant) => (
			<RestaurantListItem
				key={uuid()}
				restaurant={restaurant}
			/>
		))}
	</StyledRestaurantListWrapper>
);

export default RestaurantList;
